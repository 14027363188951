import { createStore } from 'redux';
const initialState = {
  isMobile: true //window.innerWidth < 992 ? true : false
};

export function setIsMobileVal(isMobile) {
  return {
    type: 'SET_IS_MOBILE',
    isMobile
  };
}


const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_IS_MOBILE':
      return {
        ...state,
        isMobile: action.isMobile
      };
  }
  return state;
};

export const store = createStore(reducer);
