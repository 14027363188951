import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import IconButton from '../IconButton';
import Icon from '../Icon';
import Tooltip from '../Tooltip';
import { useTranslation } from 'react-i18next';

const ToolbarButton = ({
  type = 'tool',
  id,
  icon,
  label,
  commands,
  onInteraction,
  dropdownContent,
  //
  isActive,
  className,
  ...rest
  //
}) => {
  const classes = {
    tool: isActive
      ? 'text-black'
      : 'text-common-bright hover:!bg-primary-dark hover:text-primary-light',
    toggle: isActive
      ? '!text-[#348CFD]'
      : 'text-common-bright hover:!bg-primary-dark hover:text-primary-light',
    action: isActive
      ? 'text-black'
      : 'text-common-bright hover:!bg-primary-dark hover:text-primary-light',
  };

  const bgClasses = {
    toggle: isActive && 'bg-transparent',
  };

  const activeClass = isActive ? 'active' : '';
  const shouldShowDropdown = !!isActive && !!dropdownContent;
  const iconEl = icon ? <Icon name={icon} /> : <div>{label || 'Missing icon and label'}</div>;
  const { t } = useTranslation('Buttons');
  console.log(type, commands, isActive, id);

  return (
    <div key={id}>
      {/* 注釋工具提示 */}
      {/* <Tooltip
        isSticky={shouldShowDropdown}
        content={shouldShowDropdown ? dropdownContent : label}
        tight={shouldShowDropdown}
      > */}
      <div className='flex flex-col tool-button'
        onClick={() => {
          onInteraction({
            itemId: id,
            interactionType: type,
            commands,
          });
        }}>
        <IconButton
          variant={isActive ? 'contained' : 'text'}
          bgColor={bgClasses[type]}
          size="toolbar"
          className={classnames(activeClass, classes[type], className)}

          name={label}
          key={id}
          id={id}
          {...rest}
        >
          {iconEl}
          {/* <div>
          {iconEl}
          <p className='text-white text-base text-center'>{t(label)}</p>
        </div> */}
        </IconButton>
        {/* 工具名称 */}
        <p className='text-white text-base text-center'>{t(label)}</p>
      </div>

      {/* </Tooltip> */}
    </div>
  );
};

ToolbarButton.defaultProps = {
  dropdownContent: null,
  isActive: false,
  type: 'action',
};

ToolbarButton.propTypes = {
  /* Influences background/hover styling */
  type: PropTypes.oneOf(['action', 'toggle', 'tool']),
  id: PropTypes.string.isRequired,
  isActive: PropTypes.bool,
  className: PropTypes.string,
  commands: PropTypes.arrayOf(
    PropTypes.shape({
      commandName: PropTypes.string.isRequired,
      commandOptions: PropTypes.object,
    })
  ),
  onInteraction: PropTypes.func.isRequired,
  icon: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  /** Tooltip content can be replaced for a customized content by passing a node to this value. */
  dropdownContent: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
};

export default ToolbarButton;
