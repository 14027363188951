import tr_TR from './tr-TR/';
import ar from './ar/';
import de from './de';
import en_US from './en-US/';
import es from './es/';
import fr from './fr/';
import ja_JP from './ja-JP/';
import nl from './nl/';
import pt_BR from './pt-BR/';
import vi from './vi/';
import zh from './zh/';
import test_lng from './test-LNG/';

export default {
  // ...ar,
  // ...tr_TR,
  // ...de,
  ...en_US,
  // ...es,
  // ...fr,
  // ...ja_JP,
  // ...nl,
  // ...pt_BR,
  // ...vi,
  ...zh,
  // ...test_lng,
};
